@import "../../assets/styles/vars.scss";

.main {
  // height: 70vh;
  .container {
    margin: 40px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    .card {
      width: 350px;
      height: 350px;
      @include mobile {
        width: 300px;
        height: 300px;
      }
      background-color: $white;
      border-radius: 10px;
      box-shadow: $box-shadow;
      padding: 20px;
      text-align: center;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      &:hover {
        transform: translateY(-5px);
        box-shadow: $box-shadow, 0px 0px 15px 3px rgba(0, 0, 0, 0.1);
      }

      .icon {
        font-size: 10rem;
        color: $copper;
        margin-bottom: 10px;
      }

      span {
        font-size: 1.2rem;
        font-weight: bold;
        color: $raisin-black;
        display: block;
        margin-bottom: 10px;
      }

      p {
        font-size: 1rem;
        color: $raisin-black;
      }
    }
  }
}
