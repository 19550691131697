@import "../../assets/styles/vars.scss";
.navbar {
  align-self: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
  padding: 10px;
  @include desktop {
    flex-direction: column;
    row-gap: 10px;
  }
  .routes {
    img {
      width: 100px;
      height: 100px;
    }
  }
  .options {
    display: flex;
    align-items: center;
    padding: 5px;
    background-color: $white;
    border-radius: 20px;

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      object-fit: contain;
      margin-left: 10px;
    }
    .icon {
      border-radius: 50%;
      margin: 0 2px;
      width: 20px;
      height: 20px;
      padding: 10px;
      box-sizing: content-box;
      transition: 0.2s linear;
      &:hover {
        transition: 0.2s linear;
        cursor: pointer;
        background-color: $pale-pink;
        color: $copper;
      }
      &.visited {
        color: $copper;
      }
    }
  }
}
