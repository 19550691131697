.datatable {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    .title {
      font-size: 20px;
    }
  }
  .image-preview {
    align-self: center;
    img {
      width: 50px;
      height: 50px;
      border-radius: 10%;
      object-fit: cover;
    }
    .p-image-preview-indicator {
      border: unset;
      width: 50px;
      height: 50px;
      border-radius: 10%;
    }
  }
  .p-datatable-header {
    font-size: 20px;
    border: unset;
    background-color: $white;
    color: $copper;
    padding-left: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .p-datatable-tbody {
    tr {
      td {
        text-align: center;
        border: unset;
        padding: 10px;
      }
    }
  }

  .p-datatable-thead {
    tr {
      .p-column-header-content {
        display: flex;
        justify-content: center;
        padding: 15px;
      }
      th {
        color: $pale-pink;
        background-color: $white;
        padding-bottom: 0px;
      }
    }
  }

  .datatable-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
    .icon {
      width: 20px;
      height: 20px;
      // &.edit-icon {
      //   color: $aqua-green;
      // }
      // &.delete-icon {
      //   color: $danger;
      // }
      // &.show-icon {
      //   color: $indigo-blue;
      // }
      transition: 0.2s linear;
      &:hover {
        transition: 0.2s linear;
        transform: scale(1.2);
      }
    }
  }
}
.p-paginator {
  padding: 10px 0;
  border-radius: 20px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
