@import "../../../assets/styles/vars.scss";

.edit-profile {
  min-height: 100vh;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 50px;
  @include mobile {
    margin: 0;
  }
  .images-wrapper {
    display: flex;
    @include tablet {
      flex-direction: column;
      gap: 10px;
    }
    .wrapper {
      flex: 1;
      margin: 5px;
    }
    .profile-image-upload {
      flex: 1;
      margin: 5px;
    }
  }
  input[type="file"] {
    background-color: $copper;
    color: $white;
    width: 100%;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $gold;
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    text-align: right;
    .cta {
      cursor: pointer;
      padding: 10px;
    }
  }
  .profile-card {
    width: 100%;
    border-radius: 20px;
    position: relative;
    padding: 20px;
    background-color: $transparent-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 20px;
    box-shadow: $box-shadow;

    .profile-image-upload {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 300px;
        object-fit: contain;
        margin-top: 10px;
        // border-radius: 50%;
      }
    }

    .carousel-root {
      margin-top: 10px;
      width: 100%;
      .slide {
        > div {
          display: flex;
          flex-flow: column;
        }
      }
      .carousel .slider-wrapper {
        overflow: hidden;
      }
      .carousel .slide img {
        width: 100%;
        height: 300px;
        object-fit: contain;
      }

      // Optional: Add shadow for a more defined circular look
      .carousel .slider-wrapper {
        box-shadow: unset;
      }
    }

    .slider {
      input {
        margin: 0;
        width: 100%;
      }
    }

    .content {
      padding: 0px 10px;
      text-align: center;
      input[type="text"],
      input[type="email"],
      textarea {
        resize: vertical;
        width: 100%;
        padding: 10px;
        margin: 10px 0;
        border: 1px solid $pale-pink;
        border-radius: 4px;
        font-size: 1rem;
        color: $raisin-black;
        max-height: 20vh;
      }

      .bio {
        margin-bottom: 20px;
      }

      .social-links {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .flex {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          @include mobile {
            flex-direction: column;
          }
        }
        .icon {
          font-size: 1.5rem;
          margin: 10px;
          color: $copper;
        }

        input {
          min-width: 200px;
          margin: 10px;
        }
      }
    }
  }
  .instagram-activity {
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    input {
      flex-grow: 1;
      padding: 10px;
      margin: 10px 0;
      border: 1px solid $pale-pink;
      border-radius: 4px;
      font-size: 1rem;
      color: $raisin-black;
      max-height: 20vh;
    }
  }
}
