@import "./vars";
.p-dialog {
  min-height: max-content;
  width: 70vw;
  @include mobile {
    width: 100%;
  }
  .p-progressbar-indeterminate {
    .p-progressbar-value::after,
    .p-progressbar-value::before {
      background-color: $copper;
    }
  }

  .p-inputtext {
    flex-grow: 1;
  }
  .container {
    padding: 10px;
    h5 {
      text-align: center;
      font-size: 18px;
      padding: 10px;
    }
    h4 {
      margin: 10px;
      font-size: 18px;
      text-align: center;
    }
  }
  .p-dialog-header {
    color: $white;
    background-color: $copper;
    padding: 10px;
    .p-dialog-title {
      font-size: 20px;
      font-weight: bold;
    }
    .p-dialog-header-icon {
      color: white;
      &:hover {
        background-color: darken($color: crimson, $amount: 10);
      }
    }
  }
  .input-wrapper {
    &.flex {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
    h2 {
      text-align: center;
      margin: 10px;
    }
    margin: 20px 0 20px 0;
  }
  .p-button-label {
    padding-right: 10px;
  }
  .actions {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  .danger-header {
    .p-dialog-header-icon {
      color: black;
    }
    background-color: crimson;
    gap: 10px;
  }
  .popup-content {
    padding: 20px;
    gap: 10px;
  }
  .p-dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .p-confirm-dialog-reject {
      box-shadow: unset;
      border: unset;
      width: 25%;
      padding-right: 15px;
      margin: 0;
      color: gray;
    }
    .p-confirm-dialog-accept {
      box-shadow: unset;
      border: unset;
      width: 25%;
      padding-right: 15px;
      margin: 0;
      background-color: crimson;
      color: white;
    }
  }
  .file-upload {
    text-align: center;
    .p-fileupload {
      margin: 20px;
      .p-fileupload-choose {
        width: auto;
        border: unset;
        box-shadow: unset;
        background-color: $copper;
        &:hover {
          background-color: darken($color: $copper, $amount: 10);
        }
      }
    }
    .p-fileupload-choose {
      width: 30vw;
    }
    .image-preview {
      img {
        width: 150px;
        height: 150px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
  .section {
    box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
    padding: 10px;
    margin: 30px auto;
    border-radius: 5px;
  }
}
