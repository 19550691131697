@import "../../assets/styles/vars.scss";

.video-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $transparent-white;
  border-radius: 8px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    // .icon {
    //   color: crimson;
    //   transition: 0.2s linear;
    //   box-sizing: content-box;
    //   width: 18px;
    //   height: 18px;
    //   border-radius: 8px;
    //   padding: 8px;
    //   cursor: pointer;
    //   &:hover {
    //     transition: 0.2s linear;
    //     background-color: crimson;
    //     color: $white;
    //   }
    // }
  }
  video {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    @include mobile {
      height: 250px;
    }
  }
}
