.p-dropdown {
  width: 100%;
  padding: 0px;
  max-height: 50px;
  border-radius: 4px;
  transition: 0.5s ease-in-out;
  box-shadow: 0px 1px 1px $pale-pink;
  border: unset;

  &:hover,
  &:focus {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    outline-offset: -2px;
    transition: 0.5s ease-in-out;
    border-color: $pale-pink;
  }
  .p-dropdown-item {
    font-size: 14px;
  }
  .p-dropdown-item-group {
    text-align: center;
    color: blue;
    font-weight: 900;
    font-size: 16px;
  }
}
