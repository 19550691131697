@import "../../assets/styles/vars.scss";

.request-card {
  background-color: $white;
  color: $raisin-black;
  padding: 20px;
  border-radius: 8px;
  box-shadow: $box-shadow;
  margin: 0 auto;
  text-align: left;
  position: relative;
  .status {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    background-color: $gold;
    clip-path: polygon(0 0, 0% 25%, 25% 0);
    border-top-left-radius: 8px;
  }
  &__title {
    font-size: 24px;
    margin-bottom: 20px;
    color: $copper;
  }

  &__content {
    font-size: 16px;

    p {
      margin-bottom: 10px;
    }

    strong {
      color: $gold;
    }
  }
  .actions {
    margin-top: 30px;
    text-align: center;
    .cta {
      padding: 10px;
    }
  }
}
