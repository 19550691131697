.p-inputgroup-addon {
  box-shadow: 0px 1px 1px $pale-pink;
  height: 42px;
  padding: 10px;
  border: unset;
  background-color: $white;
  img {
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
}

.custom-input {
  box-shadow: 0px 1px 1px $pale-pink;
  width: 100%;
  height: 42px;
  padding: 10px;
  border: unset;
  transition: border-color 0.3s ease-in-out, outline-offset 0.3s ease-in-out;
  &::placeholder {
    font-size: 13px;
  }
  &:hover,
  &:focus {
    border: unset;
    outline: unset;
  }

  &.p-inputtextarea {
    min-height: 100px;
  }
}

.custom-number-input {
  input::placeholder {
    font-size: 13px;
  }
  box-shadow: 0px 1px 1px $pale-pink;
  width: 100%;
  height: 42px;
  padding: 0px;
  border: unset;
  transition: border-color 0.3s ease-in-out, outline-offset 0.3s ease-in-out;

  input {
    border: unset;
    box-shadow: unset;
  }
  &:hover,
  &:focus {
    border: unset;
    outline: unset;
  }
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .icon {
    color: blue;
  }
  h4 {
    margin: 0 0 5px 5px;
  }
  .p-inputgroup {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0) !important;
    transition: 0.3s linear;
    input {
      border-radius: 0px;
    }
    &:hover,
    &:focus-within {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
  }
}

.p-fileupload {
  .p-fileupload-content > div:nth-child(3) {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
  .p-fileupload-row {
    flex-direction: column;
    .p-button-icon-only {
      display: none;
    }
    & > div {
      display: none;
    }
    .p-fileupload-file-thumbnail {
      position: relative;
      width: 150px;
      height: 150px;
      object-position: center;
      object-fit: cover;
      border: 2px solid cyan;
      border-radius: 50%;
    }
  }
  .p-fileupload-buttonbar {
    & > button:nth-child(2) {
      display: none;
    }
  }
}
