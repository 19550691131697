.lds-hourglass {
  position: fixed;
  z-index: 99;
  height: 2em; /* Adjust height as needed */
  width: 2em; /* Adjust width to match height */
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.lds-hourglass:before {
  cursor: wait;
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
}

.lds-hourglass:not(:required) {
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.lds-hourglass:not(:required):after {
  cursor: wait;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid white;
  border-top-color: #bd7430;
  animation: spinner 1.2s infinite linear;
}

/* Animation */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
