@import "../../assets/styles/vars.scss";
.login {
  min-height: 100vh;
  display: flex;
  align-items: center;
  @include tablet {
    .login-image {
      display: none;
    }
  }
  .login-section {
    color: $raisin-black;
    margin: 50px;
    flex: 1.5;
    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 10px;
      margin-bottom: 30px;
      img {
        margin-bottom: 20px;
      }
      h2 {
        color: $copper;
      }
      h3 {
        font-size: 17px;
        color: $copper;
      }
    }
    form {
      .icon {
        color: $copper;
      }
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .login-btn {
        margin-top: 30px;
        width: 100%;
      }
    }
    img {
      width: 100px;
    }
  }
  .login-image {
    flex: 2;
    position: relative;
    img {
      width: 100%;
      height: 500px;
      object-fit: contain;
    }
  }
}
