.notfound {
  position: relative;
  height: 50vh;
  direction: ltr;
  .header {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    max-width: 410px;
    width: 100%;
    text-align: center;
    .notfound-404 {
      h1 {
        font-family: "Montserrat", sans-serif;
        font-size: 80px;
        color: crimson;
      }
    }
    h2 {
      font-family: "Montserrat", sans-serif;
      color: #000;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 20px;
    }
    p {
      font-family: "Montserrat", sans-serif;
      color: #000;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 20px;
      margin-top: 10px;
    }
    a {
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      text-decoration: none;
      text-transform: uppercase;
      background: #603cff;
      display: inline-block;
      padding: 15px 30px;
      border-radius: 40px;
      color: #fff;
      font-weight: 700;
    }
  }

  @media only screen and (max-width: 767px) {
    .header .notfound-404 {
      height: 142px;
      h1 {
        font-size: 112px;
      }
    }
  }
}
