@import "../../assets/styles/vars.scss";

.services {
  min-height: 100vh;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 50px;
  @include mobile {
    margin: 0;
  }

  .wrapper {
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .carousel-root {
      width: 300px;
      margin-top: 10px;
      align-self: center;
      @include mobile {
        width: 200px;
      }
    }
  }

  input[type="file"] {
    background-color: $copper;
    color: $white;
    width: 100%;
    margin: 10px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $gold;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    text-align: right;
    .cta {
      cursor: pointer;
      padding: 10px;
    }
  }

  .service-card {
    width: 100%;
    border-radius: 20px;
    position: relative;
    padding: 20px;
    background-color: $transparent-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 20px;
    box-shadow: $box-shadow;

    .content {
      padding: 20px;
      text-align: center;
      @include mobile {
        padding: 0px;
      }
      input[type="text"],
      textarea {
        resize: vertical;
        width: 100%;
        padding: 10px;
        margin: 10px 0; 
        border: 1px solid $pale-pink;
        border-radius: 4px;
        font-size: 1rem;
        color: $raisin-black;
        max-height: 20vh;
      }
    }
    .slider {
      .slide {
        align-self: center;
      }
      input {
        margin: 0;
        width: 100%;
      }
    }
  }
}
