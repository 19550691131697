@import "../../../assets/styles/vars.scss";

.show-request {
  background-color: $transparent-white;
  padding: 20px;
  margin: 50px;
  border-radius: 8px;
  position: relative;
  .done {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $copper;
    color: $white;
    font-weight: bold;
    font-size: 10px;
    padding: 10px;
    border-top-right-radius: 8px;
  }
  @include mobile {
    margin: 20px;
  }

  &__title {
    font-size: 24px;
    margin-bottom: 20px;
    color: $copper;
  }

  &__content {
    font-size: 16px;

    p {
      margin-bottom: 10px;
      color: $raisin-black;
    }

    strong {
      color: $gold;
    }
  }

  .show-request__images {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
    img {
      max-width: 100px;
      max-height: 100px;
      border-radius: 4px;
      box-shadow: $box-shadow;
    }
  }

  .actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    text-align: right;
    .cta {
      span {
        letter-spacing: 2px !important;
      }
      cursor: pointer;
      padding: 10px;
    }
  }
}
