@import "../../../assets/styles/vars.scss";

.change-password {
  min-height: 100vh;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 50px;
  @include mobile {
    margin: 0;
  }
  .content {
    background-color: $transparent-white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    gap: 20px;
  }
  .actions {
    display: flex;
    justify-content: space-between;
    text-align: right;
    .cta {
      cursor: pointer;
      padding: 10px;
    }
  }
}
