@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import "./vars.scss";
@import "./button.scss";
@import "./datatable.scss";
@import "./input.scss";
@import "./dropdown.scss";
@import "./dialog.scss";

* {
  box-sizing: border-box;
  margin: 0;
  // padding: 0;
  font-family: Raleway, serif;
}
html {
  scroll-behavior: smooth;
}
body {
  background-color: $pale-pink;
  color: $copper;
}
html::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(0, 0, 0, 0);
}
html::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #86898d;
}
::selection {
  color: #eee;
  background: #86898d;
}
a {
  text-decoration: none;
  color: inherit;
}

.p-toast {
  z-index: 100;
  .p-toast-message {
    .p-toast-message-content {
      > svg {
        margin-right: 10px;
      }
    }
  }
}
.carousel.carousel-slider .control-arrow {
  height: 50px;
  align-self: center;
  background-color: #bd743079 !important;
}
.cta {
  font-weight: 300;
}
