.p-button {
  box-shadow: unset;
  transition: 0.1s ease-in-out;
  direction: rtl;
  border: unset;
  // gap: 10px;
  .p-icon {
    margin-left: 4px;
  }
}

.p-button.info {
  border-color: unset;
  background-color: #26a7df;
  margin-top: 20px;
  &:hover {
    background-color: darken($color: #26a7df, $amount: 10);
  }
  &:focus {
    outline: 2px solid #26a7df; /* Add custom outline on focus */
  }
  &:active {
    transform: scale(1.03);
  }
}

.p-button.success {
  border-color: unset;
  background-color: #05cd99;
  margin-top: 20px;
  &:hover {
    background-color: darken($color: #05cd99, $amount: 10);
  }
  &:focus {
    outline: 2px solid #05cd99; /* Add custom outline on focus */
  }
  &:active {
    transform: scale(1.03);
  }
}

.p-button.danger {
  border-color: unset;
  background-color: #dc3545;
  margin-top: 20px;
  &:hover {
    background-color: darken($color: #dc3545, $amount: 10);
  }
  &:focus {
    outline: 2px solid #dc3545; /* Add custom outline on focus */
  }
  &:active {
    transform: scale(1.03);
  }
}

.p-button.primary {
  border-color: unset;
  background-color: #3d86f3;
  margin-top: 20px;
  &:hover {
    background-color: darken($color: #3d86f3, $amount: 10);
  }
  &:focus {
    outline: 2px solid #3d86f3; /* Add custom outline on focus */
  }
  &:active {
    transform: scale(1.03);
  }
}

.p-button.faded {
  border-color: unset;
  color: $white;
  background-color: #f5f5f5;
  margin-top: 20px;
  &:hover {
    background-color: darken($color: #f5f5f5, $amount: 10);
  }
  &:focus {
    outline: 2px solid #f5f5f5; /* Add custom outline on focus */
  }
  &:active {
    transform: scale(1.03);
  }
}

.p-button.dark {
  border-color: unset;
  color: $white;
  background-color: #6c757d;
  margin-top: 20px;
  &:hover {
    background-color: darken($color: #6c757d, $amount: 10);
  }
  &:focus {
    outline: 2px solid #6c757d; /* Add custom outline on focus */
  }
  &:active {
    transform: scale(1.03);
  }
}
.p-button.copper {
  border-color: unset;
  background-color: #bd7430;
  margin-top: 20px;
  &:hover {
    background-color: darken($color: #bd7430, $amount: 3);
  }
  &:focus {
    outline: 2px solid #bd7430; /* Add custom outline on focus */
  }
  &:active {
    transform: scale(1.03);
  }
}
