@import "../../assets/styles/vars.scss";

.requests {
  min-height: 100vh;
  padding: 20px;
  margin: 0 50px;
  .empty {
    text-align: center;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $copper;
    color: $white;
  }
  @include mobile {
    margin: 0;
    .request-card {
      width: 250px !important;
      flex-grow: 1;
    }
  }
  .requests-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    .request-card {
      width: 400px;
      flex-grow: 1;
    }
  }
  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .cta {
      width: 50%;
      cursor: pointer;
    }
  }
}
