@import "../../assets/styles/vars.scss";

.gallery {
  min-height: 100vh;
  padding: 20px;
  margin: 0 50px;
  @include mobile {
    margin: 0;
  }
  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: $white;
    border-radius: 8px;
    margin: 10px 0;
  }
  .gallery-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }
}
